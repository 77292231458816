import axios from 'axios';

const API_URL = process.env.VUE_APP_GENERIC_RACE_API_BASE_URL;

class GenericRaceResultService {
    async getGenericRace(url) {
        let result = await axios.get(API_URL + `/resultlists/bp150_2023/rows?${url}`);
        return result;
    }
    async getGenericRaceHeader () {
        let result = await axios.get(API_URL + '/resultlists/bp150_2023/columns');
        return result;
    }
}

export default new GenericRaceResultService();